import React, { useState } from "react";
import Logo from "@assets/images/Janus-logo-black.png";
import styles from "./side-bar.module.scss";
import CopilotIcon from "@assets/componentIcons/CopilotIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { navigationItems } from "@components/layout/superadmin-layout/sideBar/constans";

const SideBar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [activeHoverElementId, setActiveHoverElementId] = useState("");

  const getActiveLinkId: "0" | "1" | "2" | "3" | "4"= (() => {
    const link = pathname.split("/")[pathname.split("/").length - 1];

    switch (link) {
      case "dashboard":
        return "0";
      case "users":
        return "1";
      case "promptpanel":
        return "2";
      case "billing":
        return "3";
      case "resource-center":
        return "4";
      default:
        return "0";
    }
  })();

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarHeader}>
        <img src={Logo} alt="logo" className={styles.sidebarLogo} />
        <span className={styles.sidebarLogoText}>master admin</span>
      </div>
      <ul className={styles.sidebarNavigation}>
        {navigationItems?.map((el, i) => {
          return (
            <li
              className={styles.sidebarNavigationItem}
              key={i}
              id={`${i}`}
              onMouseEnter={() => setActiveHoverElementId(`${i}`)}
              onMouseLeave={() => setActiveHoverElementId("")}
              onClick={() => navigate(el.navigation)}
            >
              <el.icon
                color={
                  (`${i}` === activeHoverElementId ||
                    getActiveLinkId === `${i}`) &&
                  "#00B840"
                }
              />
              <p style={{ color: getActiveLinkId === `${i}` ? "#00B840" : "" }}>
                {el.name}
              </p>
            </li>
          );
        })}
      </ul>
      <div
        className={styles.sidebarNavigationItem}
        style={{ alignItems: "flex-start" }}
        onMouseEnter={() => setActiveHoverElementId(`link`)}
        onMouseLeave={() => setActiveHoverElementId("")}
      >
        <p>Launch Copilot</p>
        <CopilotIcon color={activeHoverElementId === "link" && "#00B840"} />
      </div>
      {/*<div className={styles.sidebarFooter}>*/}
      {/*  <p className={styles.sidebarFooterDesc}>Have any questions?</p>*/}
      {/*  <div className={styles.sidebarFooterButton}>Contact Sales</div>*/}
      {/*</div>*/}
    </div>
  );
};

export default SideBar;
