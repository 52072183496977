import React, { useEffect, useState } from "react";
import DashboardCard from "@components/layout/admin-layout/components/dashboardCard/DashboardCard";
import styles from "./dashboard.module.scss";
import { useInviteModal } from "@components/ModalContext/InviteModalContext";
import InviteModal from "@components/layout/admin-layout/components/inviteModal/InviteModal";
import { useCartDataContext } from "@components/layout/superadmin-layout/SuperAdminLayout";
import { io } from "socket.io-client";
import { baseUrlMasterAdmin } from "@api/const";

const Dashboard = () => {
  const { isModalOpen } = useInviteModal();
  const cardData = useCartDataContext();
  const [socketAnswer, setSocketAnswer] = useState(null);

  useEffect(() => {
    const socket = io(
      baseUrlMasterAdmin,
      {
        extraHeaders: {
          Origin: ":",
        },
      },
    );
    socket.emit("getMetrics");
    socket.on("metrics", (answer) => {
      setSocketAnswer(answer);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  console.log(
    "socketAnswer",
    socketAnswer
  );

  return (
    <div className={styles.dashboard}>
      {isModalOpen && <InviteModal />}
      <div className={styles.dashboardCards}>
        <DashboardCard
          type="CPU usage"
          color={
            ((socketAnswer && +socketAnswer?.cpu_usage.replaceAll("%", "")) ||
              0) > 70
              ? "#f89e06"
              : ((socketAnswer &&
                  +socketAnswer?.cpu_usage.replaceAll("%", "")) ||
                  0) > 90
              ? "red"
              : "#3DD03A"
          }
          count={(socketAnswer && socketAnswer?.cpu_usage.slice(0, 4)) || 0}
          size="small"
        />
        <DashboardCard
          type="Memory usage"
          color={
            ((socketAnswer &&
              +socketAnswer?.memory_usage.replaceAll("%", "")) ||
              0) > 70
              ? "#f89e06"
              : ((socketAnswer &&
                  +socketAnswer?.memory_usage.replaceAll("%", "")) ||
                  0) > 90
              ? "red"
              : "#3DD03A"
          }
          count={(socketAnswer && socketAnswer?.memory_usage.slice(0, 4)) || 0}
          size="small"
        />
        <DashboardCard
          type="Total Tokens Consumed"
          color="#3DD03A"
          count={`${(cardData && cardData["total tokens"]) || 0}`}
          size="small"
        />
      </div>
      <div className={styles.dashboardTableWrap}>
        <h2 className={styles.dashboardTableWrapTitle}>Performance insights</h2>
        <div className={styles.dashboardTable}>
          <div className={styles.dashboardTableHeadItem}>Resources</div>
          <div className={styles.dashboardTableHeadItem}>Utilization</div>
          <div className={styles.dashboardTableHeadItem}>Description</div>

          <div className={styles.dashboardTableBodyItem}>Request count</div>
          <div
            className={styles.dashboardTableBodyItem}
            style={{ padding: "0 25px" }}
          >
            {(socketAnswer && socketAnswer?.request_count.slice(0, 4)) || '--'}
          </div>
          <div className={styles.dashboardTableBodyItem}>
            <i>Request count</i> shows how much the application is being used
            and can help identify any potential problems. Too many requests{" "}
            <b>({">"} 700)</b> can can affect the app’s performance and
            stability.
          </div>

          <div className={styles.dashboardTableBodyItem}>Request latency:</div>
          <div
            className={styles.dashboardTableBodyItem}
            style={{ padding: "0 25px" }}
          >
            {(socketAnswer && socketAnswer?.request_latency.slice(0, 4)) || '--'}
          </div>
          <div className={styles.dashboardTableBodyItem}>
            <i>Request latency</i> is the amount of time it takes for the app to
            respond to a user's request. If it takes too long, the user's
            experience may be negatively affected. The current threshold for
            this metric is set at <b>5 seconds</b>, but it may be adjusted as we
            gather more data on how real users use the app.
          </div>

          <div className={styles.dashboardTableBodyItem}>
            Concurrent requests
          </div>
          <div
            className={styles.dashboardTableBodyItem}
            style={{ padding: "0 25px" }}
          >
            {(socketAnswer && socketAnswer?.concurrent_request?.slice(0, 4)) ||
              '--'}
          </div>
          <div className={styles.dashboardTableBodyItem}>
            <i>Concurrent requests</i> reflect the number of users accessing the
            web app at the same time. A high number of concurrent requests {">"}{" "}
            <b>800</b> can lead to slower response times, errors, and even
            crashes.
          </div>

          <div className={styles.dashboardTableBodyItem}>Instance count</div>
          <div
            className={styles.dashboardTableBodyItem}
            style={{ padding: "0 25px" }}
          >
            {(socketAnswer && socketAnswer?.Instance_count?.slice(0, 4)) || 0}
          </div>
          <div className={styles.dashboardTableBodyItem}>
            <i>Instance count</i> is the number of active copies of the web
            application that are running. It indicates the current demand for
            the application. By tracking this metric, we can ensure that there
            are enough resources allocated to handle the incoming traffic. The
            instance count should always be 1 or above.
          </div>
        </div>
        <div className={styles.dashboardAlertsWrapTitle}>
          <h2 className={styles.dashboardAlertsWrapTitle}>Alerts</h2>
          <div className={styles.dashboardAlertsWrapSubTitle}>Message</div>
          <div className={styles.dashboardAlertsTable}>
            {socketAnswer && socketAnswer?.alerts?.length ? (
              socketAnswer?.alerts?.slice(-10).map((el, i) => {
                return (
                  <p key={i} className={styles.dashboardAlertsTableItem}>
                    {el["Memory usage"]}
                  </p>
                );
              })
            ) : (
              <div>None</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
