import React, { memo, useEffect, useState } from 'react';

import { Form, Select, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import classNames from 'classnames';

import ArrowIconWhite from '@assets/icons/ArrowIconWhite';
import StopGenerationIcon from '@assets/icons/StopGenerationIcon';
import Copied from '@assets/icons/copied.svg';
import Copy from '@assets/icons/copy.svg';
import CopyIcon from '@assets/playground/icon_copy.svg';
import RegenerateIcon from '@assets/playground/icon_regenerate.svg';
import Close from '@assets/icons/cross.svg';
import down from '@assets/images/down.svg';
import Spinner from '@assets/icons/spinner.svg';
import UploadImage from '@assets/playground/icon_upload_image.svg';
import UploadDocument from '@assets/playground/icon_upload_doc.svg';
import ChatAIOutputIcon from '@assets/icons/chat_ai_output.png';
import IconAdd from '@assets/playground/icon_add.svg';
import IconInfo from '@assets/playground/icon_info.svg';
import { ChatRole, IChat } from '@components/pages/project/Playground/types';
import MarkdownViewer, {
  processMarkdown,
} from '@components/common-components/markdown-viewer';
import { useStore } from '@stores/root-store';
import { validateMessages } from '@utils/json-data';
import pdfButtonIcon from '../../../../assets/icons/writing-tips-button.svg';
import style from './style.module.scss';
import testSvg from '@assets/icons/test.svg';
import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import { allCountries } from '@utils/helpers/allCountries';

interface AdvisorFormProps {
  Text: JSX.Element;
  disabled: boolean;
  maxLength: number;
  handleSubmit: (data?: any) => void;
  handleRegenerate: () => void;
  onDownloadPdf?: () => void;
  setInput?: (val: string) => void;
  setChat?: any;
  chat: IChat[];
  research: string;
  setResearch: (val: string) => void;
  scoring: string;
  setScoring: (val: string) => void;
  sector: string;
  setSector: (val: string, fn?: any) => void;
  country: string;
  setCountry: (val: string) => void;
  form: any;
  initialState: any;
  selectedFile?: File | null;
  setSelectedFile?: (file: File) => void;
  selectedDocument?: File | null;
  setSelectedDocument?: (file: File) => void;
  documentsMap: any;
  placeholder?: string;
  isFormPage?: boolean;
  isSubmitting?: boolean;
  value?: string;
  input?: string;
  question?: string;
  stopGeneration?: () => void;
}

const PlaygroundForm: React.FC<AdvisorFormProps> = ({
  Text,
  disabled,
  maxLength,
  handleSubmit,
  handleRegenerate,
  form,
  initialState,
  placeholder,
  isFormPage,
  value,
  isSubmitting,
  question,
  onDownloadPdf,
  input,
  selectedFile,
  setSelectedFile,
  selectedDocument,
  setSelectedDocument,
  documentsMap,
  setInput,
  chat,
  setChat,
  research,
  setResearch,
  scoring,
  setScoring,
  sector,
  setSector,
  country,
  setCountry,
  stopGeneration,
}) => {
  const {
    //user: { getUserType },
    modelType: { changeModelType, getSelectedModelType },
  } = useStore(null);
  const [copiedIndex, setCopiedIndex] = React.useState<number>(-1);
  const [preview, setPreview] = useState('');
  const [showRegenerate, setShowRegenerate] = useState(false);
  const getUserType = 'enterprise';

  const onClickEnter = (event) => {
    if (event.key === 'Enter' && input.trim().length) {
      event.preventDefault();
      handleSubmit('');
    }
  };

  const [openModal, setOpenModal] = useState(
    localStorage.getItem('showPlaygroundTooltip') || 'true'
  );

  useEffect(() => {
    form.setFieldValue('q', input);
  }, [input]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    const chat = document.getElementById('chat');
    chat.scrollTop = chat.scrollHeight;
  }, [chat]);

  useEffect(() => {
    if (getUserType === 'enterprise') {
      changeModelType('GPT-4 (Turbo)');
    }
  }, [getUserType]);

  useEffect(() => {
    if (!copiedIndex) return;

    setTimeout(() => {
      setCopiedIndex(null);
    }, 2000);
  }, [copiedIndex]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const systemIcon = (
    <div className={style.chatMessageSystemAIIcon}>
      <img src={ChatAIOutputIcon} alt='AI' />
    </div>
  );

  return (
    <>
      <div className={style.formWrapper + ' playgroundns'}>
        <Form
          className={style.form}
          form={form}
          autoComplete='false'
          onFinish={handleSubmit}
          validateMessages={validateMessages}
          layout='vertical'
          initialValues={{
            q: input,
          }}
        >
          <div
            className={style.wrapper}
            style={{
              maxHeight: selectedFile ? '60vh' : '70vh',
            }}
          >
            <div className={style.chat} id='chat'>
              <div
                key='initialMessage'
                className={[style.chatMessage, style.chatMessageSystem].join(
                  ' '
                )}
              >
                {systemIcon}{' '}
                <div className={style.chatMessageText}>
                  <p>{Text}</p>
                </div>
              </div>
              {!!chat.length
                ? chat.map((el: IChat, index: number) => {
                    return (
                      <div
                        key={index}
                        className={[
                          style.chatMessage,
                          el.role === ChatRole.CHAT
                            ? style.chatMessageSystem
                            : style.chatMessageUser,
                        ].join(' ')}
                      >
                        {el.role === ChatRole.CHAT ? systemIcon : null}
                        <div className={style.chatMessageText}>
                          {el.role === ChatRole.USER ? (
                            el.message
                          ) : el.isLastChatMessage === true &&
                            el.message === '' ? (
                            <div className={style.systemGeneratingResponse}>
                              <img src={Spinner} /> Generating a response
                            </div>
                          ) : el.message === '__INTERNAL_STOPPED__' 
                                ? (
                                  <div className={style.systemGeneratingResponse}>
                                    Stopped
                                  </div>
                                )
                                :(
                                  <MarkdownViewer text={el.message} type="playground" />
                                )
                          }
                        </div>
                        {el.image && (
                          <img src={URL.createObjectURL(el.image)} alt='' />
                        )}
                        {documentsMap[el.id] && (
                          <div className={style.chatMessageDocument}>
                            Selected File: <b>{documentsMap[el.id].name}</b>
                          </div>
                        )}
                        <div className={style.chatMessageControls}>
                          <div
                            className={style.copyIcon}
                            onClick={async () => {
                              const { htmlContent, plainText } =
                                await processMarkdown(el.message);
                              // Copy both HTML and plain text to the clipboard
                              await navigator.clipboard.write([
                                new ClipboardItem({
                                  'text/html': new Blob([htmlContent], {
                                    type: 'text/html',
                                  }),
                                  'text/plain': new Blob([plainText], {
                                    type: 'text/plain',
                                  }),
                                }),
                              ]);

                              setCopiedIndex(index);
                            }}
                          >
                            {el.role === ChatRole.CHAT &&
                              el.message &&
                              (copiedIndex === index ? (
                                <img
                                  style={{ width: '24px', height: '24px' }}
                                  src={Copied}
                                  alt='copied'
                                />
                              ) : (
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={CopyIcon}
                                  alt='copy'
                                />
                              ))}
                          </div>
                          {el.isLastChatMessage && el.message && (
                            <div
                              className={style.copyIcon}
                              onClick={async () => {
                                //setShowRegenerate(true)
                                handleRegenerate();
                              }}
                            >
                              <img
                                style={{ cursor: 'pointer' }}
                                src={RegenerateIcon}
                                alt='Regenerate response'
                              />
                            </div>
                          )}
                          {el.isLastChatMessage &&
                            el.message &&
                            showRegenerate && (
                              <div
                                className={style.copyIcon}
                                style={{ lineHeight: '16px' }}
                              >
                                Do you want to regenerate?{' '}
                                <a
                                  href='#'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleRegenerate();
                                    setShowRegenerate(false);
                                  }}
                                >
                                  yes
                                </a>
                                {' / '}
                                <a
                                  href='#'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setShowRegenerate(false);
                                  }}
                                >
                                  no
                                </a>
                              </div>
                            )}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
            <div className={style.bottomSection}>
              {openModal === 'true' ? (
                <div className={style.bottomSectionTooltip}>
                  <div className={style.bottomSectionTooltipTop}>
                    Getting Started
                  </div>
                  <div className={style.bottomSectionTooltipContent}>
                    Start by selecting below the subject country name and the
                    sector you are most interested in. Once the output has been
                    generated, continue the chat by entering your queries into
                    the input box below and pressing the enter button.
                  </div>
                  <div className={style.bottomSectionTooltipFooter}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        localStorage.setItem('showPlaygroundTooltip', 'false');
                        setOpenModal('false');
                      }}
                    >
                      Got It
                    </button>
                  </div>
                </div>
              ) : null}
              <Select
                defaultValue={'GPT-4 (Turbo)'}
                style={{ width: '172px', textAlign: 'start' }}
                popupClassName='playgroundns'
                suffixIcon={<img src={down} alt='down icon' />}
                onChange={(value) => {
                  if (value === 'close') {
                    return;
                  }
                  changeModelType(value);
                }}
                value={getSelectedModelType}
                placement='topRight'
                optionLabelProp='show'
                options={[
                  {
                    label: (
                      <span
                        style={{
                          position: 'relative',
                          paddingLeft: '20px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {getSelectedModelType === 'Gemini' && (
                          <CheckOutlined
                            style={{
                              position: 'absolute',
                              left: '0',
                            }}
                          />
                        )}
                        Gemini
                      </span>
                    ),
                    show: 'Gemini',
                    value: 'Gemini',
                  },
                  {
                    label: (
                      <span
                        style={{
                          position: 'relative',
                          paddingLeft: '20px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {getSelectedModelType === 'GPT-4 (Turbo)' && (
                          <CheckOutlined
                            style={{
                              position: 'absolute',
                              left: '0',
                            }}
                          />
                        )}
                        GPT-4 (Turbo)
                      </span>
                    ),
                    show: 'GPT-4 (Turbo)',
                    value: 'GPT-4 (Turbo)',
                  },
                  // {
                  //   label: (
                  //     <span
                  //       style={{
                  //         position: 'relative',
                  //         paddingLeft: '20px',
                  //         display: 'flex',
                  //         alignItems: 'center',
                  //       }}
                  //     >
                  //       {getSelectedModelType === 'In-house' && (
                  //         <CheckOutlined
                  //           style={{
                  //             position: 'absolute',
                  //             left: '0',
                  //           }}
                  //         />
                  //       )}
                  //       <span className={style.label}>
                  //         In-house
                  //         <img
                  //           src={testSvg}
                  //           alt='test icon'
                  //           width={50}
                  //           style={{ marginLeft: '10px' }}
                  //         />
                  //       </span>
                  //     </span>
                  //   ),
                  //   show: 'In-house',
                  //   value: 'In-house',
                  // },
                  {
                    label: (
                      <span
                        style={{
                          display: 'flex',
                          height: '100%',
                          alignItems: 'flex-end',
                          justifyContent: 'center',
                        }}
                      >
                        <DownOutlined />
                      </span>
                    ),
                    show: '',
                    value: 'close',
                  },
                ]}
              />
              {/*<img src={IconInfo} className='iconInfo' title='Select model' />*/}

              {/* New temp stuff start*/}
              <Select
                style={{
                  marginLeft: '25px',
                  width: '260px',
                  textAlign: 'start',
                }}
                suffixIcon={<img src={down} alt={'down icon'} />}
                onChange={(value) => {
                  if (value === 'close') {
                    return;
                  }
                  setCountry(value);
                }}
                value={country}
                disabled={false}
                placement='topRight'
                options={[
                  {
                    label: (
                      <span
                        style={{
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Select Country
                      </span>
                    ),
                    show: 'Select Country',
                    value: '',
                  },
                  ...allCountries.map((c) => {
                    return {
                      label: (
                        <span
                          style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {c.label}
                        </span>
                      ),
                      show: c.label,
                      value: c.value,
                    };
                  }),
                  {
                    label: (
                      <span
                        style={{
                          display: 'flex',
                          height: '100%',
                          alignItems: 'flex-end',
                          justifyContent: 'center',
                        }}
                      >
                        <DownOutlined />
                      </span>
                    ),
                    show: '',
                    value: 'close',
                  },
                ]}
              />
              {/*<img src={IconInfo} className='iconInfo' title='Select Country' />*/}
              <Select
                style={{
                  marginLeft: '25px',
                  width: '350px',
                  textAlign: 'start',
                }}
                suffixIcon={<img src={down} alt={'down icon'} />}
                onChange={(value) => {
                  if (value === 'close') {
                    return;
                  }
                  setSector(value);
                }}
                value={sector}
                disabled={false}
                placement='topRight'
                options={[
                  {
                    label: (
                      <span
                        style={{
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Select Sector
                      </span>
                    ),
                    show: 'Select Sector',
                    value: '',
                  },
                  ...[
                    {
                      value: 'Buildings, cities, industries, and appliances',
                      label: 'Buildings, cities, industries, and appliances',
                    },
                    {
                      value: 'Ecosystems and ecosystem services',
                      label: 'Ecosystems and ecosystem services',
                    },
                    {
                      value: 'Energy generation and access',
                      label: 'Energy generation and access',
                    },
                    {
                      value: 'Forests and land use',
                      label: 'Forests and land use',
                    },
                    {
                      value: 'Health, food, and water security',
                      label: 'Health, food, and water security',
                    },
                    {
                      value: 'Infrastructure and built environment',
                      label: 'Infrastructure and built environment',
                    },
                    {
                      value: 'Livelihoods of people and communities',
                      label: 'Livelihoods of people and communities',
                    },
                    {
                      value: 'Transport',
                      label: 'Transport',
                    },
                    {
                      value: 'Other',
                      label: 'Other',
                    },
                  ].map((s) => {
                    return {
                      label: (
                        <span
                          style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {s.label}
                        </span>
                      ),
                      show: s.label,
                      value: s.value,
                    };
                  }),
                  {
                    label: (
                      <span
                        style={{
                          display: 'flex',
                          height: '100%',
                          alignItems: 'flex-end',
                          justifyContent: 'center',
                        }}
                      >
                        <DownOutlined />
                      </span>
                    ),
                    show: '',
                    value: 'close',
                  },
                ]}
              />
              {/*<img src={IconInfo} className='iconInfo' title='Select Country' />*/}
              <Select
                style={{
                  marginLeft: '25px',
                  width: '241px',
                  textAlign: 'start',
                }}
                suffixIcon={<img src={down} alt={'down icon'} />}
                onChange={(value) => setScoring(value)}
                disabled={true}
                value={'Select Institutional Database'}
                placement='topRight'
                options={[
                  {
                    label: 'Select Institutional Database',
                    value: '',
                  },
                ]}
              />
              {/*// TODO: disabled until populated*/}
              {/*<img src={IconInfo} className='iconInfo' title='Select Institutional Database'/>*/}
              {/* New temp stuff end*/}
              {/* Old commented out section start*/}
              {/* <Select
                size="middle"
                style={{
                  marginLeft: "25px",
                  width: "241px",
                  textAlign: "start",
                }}
                popupClassName="playgroundns"
                suffixIcon={<img src={down} alt="down icon" />}
                onChange={(value) => {
                  // Check if the last option was selected
                  if (value === "close") {
                    // Prevent any value selection and close the dropdown
                    return;
                  }
                  // Otherwise, set the selected value as usual
                  setScoring(value);
                }}
                value={scoring}
                placement="topRight"
                optionLabelProp="show"
                options={[
                  {
                    label: (
                      <span
                        style={{
                          position: "relative",
                          paddingLeft: "24px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {scoring === "" && (
                          <CheckOutlined
                            style={{
                              position: "absolute",
                              left: "0",
                            }}
                          />
                        )}
                        Select Scoring
                      </span>
                    ),
                    show: "Select Scoring",
                    value: "",
                  },
                  {
                    label: (
                      <span
                        style={{
                          position: "relative",
                          paddingLeft: "24px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {scoring === "gif_itap_assessment_adaptation" && (
                          <CheckOutlined
                            style={{
                              position: "absolute",
                              left: "0",
                            }}
                          />
                        )}
                        iTAP Adaptation
                      </span>
                    ),
                    show: "iTAP Adaptation",
                    value: "gif_itap_assessment_adaptation",
                  },
                  {
                    label: (
                      <span
                        style={{
                          position: "relative",
                          paddingLeft: "24px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {scoring === "gif_itap_assessment_mitigation" && (
                          <CheckOutlined
                            style={{
                              position: "absolute",
                              left: "0",
                            }}
                          />
                        )}
                        iTAP Mitigation
                      </span>
                    ),
                    show: "iTAP Mitigation",
                    value: "gif_itap_assessment_mitigation",
                  },
                  {
                    label: (
                      <span
                        style={{
                          position: "relative",
                          paddingLeft: "24px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {scoring === "gif_itap_assessment_blended" && (
                          <CheckOutlined
                            style={{
                              position: "absolute",
                              left: "0",
                            }}
                          />
                        )}
                        TAP Blended
                      </span>
                    ),
                    show: "TAP Blended",
                    value: "gif_itap_assessment_blended",
                  },
                  {
                    label: (
                      <span
                        style={{
                          position: "relative",
                          paddingLeft: "24px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {scoring === "reviewer_comments_archive" && (
                          <CheckOutlined
                            style={{
                              position: "absolute",
                              left: "0",
                            }}
                          />
                        )}
                        Reviewer Comments Archive
                      </span>
                    ),
                    show: "Reviewer Comments Archive",
                    value: "reviewer_comments_archive",
                  },
                  {
                    label: (
                      <span
                        style={{
                          display: "flex",
                          height: "100%",
                          alignItems: "flex-end",
                          justifyContent: "center",
                        }}
                      >
                        <DownOutlined />
                      </span>
                    ),
                    show: "",
                    value: "close",
                  },
                ]}
              />
              <img src={IconInfo} className='iconInfo' title='Select scoring'/>
              <Select
                style={{
                  marginLeft: "25px",
                  width: "260px",
                  textAlign: "start",
                }}
                suffixIcon={<img src={down} alt={"down icon"} />}
                onChange={(value) => setResearch(value)}
                value={research}
                disabled={true}
                placement="topRight"
                options={[
                  {
                    label: "Select AF Reviewer Comments",
                    value: "",
                  },
                  {
                    label: "Jamaica Climate Science",
                    value: "jamaica_climate_science",
                  },
                  {
                    label: "Jamaica Climate Policy",
                    value: "jamaica_climate_policy",
                  },
                ]}
              />
              <img src={IconInfo} className='iconInfo' title='Select AF Reviewer Comments'/>
              <Select
                style={{
                  marginLeft: "25px",
                  width: "260px",
                  textAlign: "start",
                }}
                suffixIcon={<img src={down} alt={"down icon"} />}
                onChange={(value) => setScoring(value)}
                disabled={true}
                value={"Select GCF Reviewer Comments"}
                placement="topRight"
                options={[
                  {
                    label: "Select GCF Reviewer Comments",
                    value: "",
                  },
                ]}
              />
              <img src={IconInfo} className='iconInfo' title='Select GCF Reviewer Comments'/>
              <Select
                style={{
                  marginLeft: "25px",
                  width: "241px",
                  textAlign: "start",
                }}
                suffixIcon={<img src={down} alt={"down icon"} />}
                onChange={(value) => setScoring(value)}
                disabled={true}
                value={"Select Institutional Database"}
                placement="topRight"
                options={[
                  {
                    label: "Select Institutional Database",
                    value: "",
                  },
                ]}
              />
              <img src={IconInfo} className='iconInfo' title='Select Institutional Database'/> */}
              {/* Old commented out section end*/}
            </div>
          </div>
          <div>
            <Form.Item
              name={'q'}
              rules={[
                { required: !disabled, message: 'This field is required' },
              ]}
            >
              <div
                style={{
                  border: '1px solid #E6E6E6',
                  borderRadius: '5px',
                  background: '#fff',
                  boxShadow: '0px 1px 4px 1px #0000000F',
                  display: 'flex',
                  alignItems: 'end',
                  paddingTop: preview || selectedDocument ? '57px' : undefined,
                }}
              >
                <TextArea
                  style={{ border: '0px', resize: 'none' }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      onClickEnter(e);
                    }
                  }}
                  placeholder='Enter your query here'
                  // disabled={disabled}
                  value={input}
                  onChange={(e) => {
                    setInput(e.target.value);
                  }}
                  autoSize
                  className={classNames(
                    style.textarea,
                    style.textareaFont,
                    isSubmitting && style.textareaWithoutItalicStyle
                  )}
                />
                {preview && (
                  <div className={style.previewWrapper}>
                    <div>
                      <img src={preview} alt='preview' />
                      <div
                        className={style.clearFile}
                        onClick={() => {
                          setSelectedFile(null);
                          setPreview('');
                        }}
                      >
                        <img src={Close} alt='Clear file' />
                      </div>
                    </div>
                  </div>
                )}
                {selectedDocument && (
                  <div className={style.previewWrapper}>
                    <div>
                      Selected File: {selectedDocument.name}
                      <div
                        className={style.clearFile}
                        onClick={() => {
                          setSelectedDocument(null);
                        }}
                      >
                        <img src={Close} alt='Clear file' />
                      </div>
                    </div>
                  </div>
                )}
                <div className={style.textareaIcons}>
                  <div className={style.textareaIconsAdd}>
                    <img className={style.icon} src={IconAdd} />
                    <div className={style.content}>
                      <Upload
                        accept='image/*'
                        onChange={(info) => {
                          setSelectedFile(info.file.originFileObj);
                        }}
                        itemRender={() => null}
                      >
                        <div className={style.contentUploadWrapper}>
                          <img src={UploadImage} alt='upload' />
                          <div className={style.description}>Upload image</div>
                        </div>
                      </Upload>
                      <Upload
                        accept='.pdf,.doc,.docx'
                        onChange={(info) => {
                          setSelectedDocument(info.file.originFileObj);
                        }}
                        itemRender={() => null}
                      >
                        <div className={style.contentUploadWrapper}>
                          <img src={UploadDocument} alt='upload document' />
                          <div className={style.description}>
                            Upload file
                            <br />
                            <span className={style.subDescription}>
                              Only .doc and pdfs
                            </span>
                          </div>
                        </div>
                      </Upload>
                    </div>
                  </div>

                  <div
                    className={style.textareaIcon}
                    onClick={() => {
                      if (isSubmitting) {
                        stopGeneration()
                      } else {
                        handleSubmit()
                      }
                    }}
                    style={{
                      backgroundColor: isSubmitting ? 'transparent' : '',
                      height: isSubmitting ? '35px' : undefined,
                      width: isSubmitting ? '35px' : undefined,
                    }}
                  >
                    {isSubmitting ? (
                      <div
                        style={{ width: '35px', height: '35px' }}
                        onClick={() => stopGeneration()}
                      >
                        <StopGenerationIcon />
                      </div>
                    ) : (
                      <ArrowIconWhite />
                    )}
                  </div>
                </div>
              </div>
            </Form.Item>

            {/*{isFormPage && (*/}
            {/*  <p style={{ marginTop: "15px", fontStyle: "italic" }}>*/}
            {/*    Maximum {maxLength} words*/}
            {/*  </p>*/}
            {/*)}*/}
            <div className={style.bottomText}>
              Climate Finance Playground can make mistakes. Check important
              information.
            </div>
            <div className={style.bottomSubtext}>
              In-house LLM built with Meta Llama 3
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

//export default memo(PlaygroundForm);
export default PlaygroundForm;
