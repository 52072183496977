// MarkdownViewer.js

import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styles from './styles.module.scss'

// Import remark and plugins for processing Markdown
import { remark } from 'remark';
import remarkHtml from 'remark-html';
import stripMarkdown from 'strip-markdown';

const fixWSIssue = (str) => (typeof str === 'string' ? str.replace(/\u00A0/g, ' ') : str);

// Async function to process Markdown text into HTML and plain text
export const processMarkdown = async (text) => {
  const fixedText = fixWSIssue(text);

  // Convert Markdown to HTML using remark with the same plugins
  const htmlFile = await remark()
    .use(remarkGfm)
    .use(remarkHtml)
    .process(fixedText);

  const htmlContent = String(htmlFile);

  // Extract plain text by stripping Markdown syntax
  const plainTextFile = await remark()
    .use(remarkGfm)
    .use(stripMarkdown)
    .process(fixedText);

  const plainText = String(plainTextFile);

  return {
    htmlContent,
    plainText,
  };
};

// MarkdownViewer component
const MarkdownViewer = ({ text, type = 'copilotReport' }) => {
  const isCopilotReport = type === 'copilotReport'
  // TODO: implement other types when necessary, "playground" for Playground chat styling

  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} disallowedElements={["b", "strong", "hr"]}
     className={isCopilotReport ? styles.markdownCopilotReport : styles.markdownWrapper}>
        {fixWSIssue(text)}
    </ReactMarkdown>
  );
};

export default MarkdownViewer;
