import { Button, Form, Select } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";
import testSvg from "@assets/icons/test.svg";

import RegenerateIcon from "@assets/icons/RegenerateIcon";
import down from "@assets/images/down.svg";
import GoBack from "@components/common-components/go-back";
import SaveQuit from "@components/common-components/save-quit";
import { useStore } from "@stores/root-store";

import style from "./style.module.scss";
import ReportModal from "@components/modal/report-modal";

export interface CommonFooterButtonProps {
  handleSubmit?: any;
  handlegoback?: any;
  handleSaveAndQuit?: any;
  handleGoNext?: any;
  handleRegenrate?: any;
  isResult?: boolean;
  isSubmitting?: boolean;
  isSubmit?: boolean;
  isLoading?: boolean;
  isLoadingRegenrate?: boolean;
  isLoadingSubmit?: boolean;
  form?: any;
  handleQuickNext?: any;
  customStyle?: any;
  setGenerateText?: any;
  withRegenerate?: boolean;
  isTooltip?: boolean;
  setIsSubmitting?: any;
  disableSubmit?: boolean;
}

const CommonFooterButton = ({
  handleSubmit,
  handlegoback,
  isSubmitting,
  handleSaveAndQuit,
  handleRegenrate,
  handleGoNext,
  isResult = false,
  isLoading = false,
  isLoadingRegenrate,
  isLoadingSubmit,
  form,
  handleQuickNext,
  customStyle,
  setGenerateText,
  isSubmit,
  withRegenerate,
  isTooltip,
  setIsSubmitting,
  disableSubmit,
}: CommonFooterButtonProps) => {
  const { pathname } = useLocation();
  const [isTooltipShow, setIsTooltipShow] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isRegenerating, setIsRegenerating] = useState(false);

  const {
    user: { getUserType },
    modelType: { changeModelType, getSelectedModelType },
  } = useStore(null);

  const isHiddenGoBackButton = pathname.includes("/playground/");
  const isHiddenModelSelection = pathname.includes("/results/");

  const tooltipHandler = (visible) => {
    if (isFirstRender && visible) {
      setIsTooltipShow(visible);
      setIsFirstRender(false);
    } else {
      setIsTooltipShow(false);
    }
  };

  useEffect(() => {
    if (!(isSubmitting && isRegenerating)) {
      setIsRegenerating(false);
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (getUserType === "enterprise") {
      changeModelType("Pegasus");
    }
  }, [getUserType]);

  const tooltipData = () => {
    return (
      <div style={{ paddingLeft: 11, paddingRight: 11, color: "#000000" }}>
        <p
          style={{
            margin: 0,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "30px",
            color: "#000000",
          }}
        >
          Important!
        </p>
        <p
          style={{
            marginTop: 4,
            fontFamily: "Inter",
            fontStyle: "normal",
            fontSize: 14,
          }}
        >
          By selecting this button, the model will regenerate an alternative
          result to the previous one. The new result will be generated below the
          most recent output.
        </p>
        {/* <hr /> */}
        <div
          style={{
            marginTop: 12,
            padding: "12px 20px",
            marginLeft: -19,
            marginRight: -19,
            marginBottom: -6,
            border: "1px solid #E0E0E0",
          }}
        >
          <Button
            onClick={() => setIsTooltipShow(false)}
            style={{
              backgroundColor: "#00B840",
              width: 66,
              padding: " 6px 20px 10px 15px",
              height: 34,
              color: "#FFFFFF",
            }}
          >
            Got it
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {isResult ? (
        <div
          style={customStyle && customStyle}
          className={style.footerButtonsDiv}
        >
          <div className={style.leftBtnContainer}>
            <Button
              loading={isLoadingSubmit}
              disabled={isLoadingSubmit || disableSubmit}
              onClick={() => handleGoNext()}
              htmlType="submit"
              className={style.nextButton}
            >
              {isResult ? "Next" : isSubmit ? "Next" : "Submit"}
            </Button>

            {!isLoadingRegenrate ? (
              <></>
            ) : (
              // <Tooltip open={isTooltipShow} onOpenChange={tooltipHandler} overlayInnerStyle={{width:294}} className={style.tooltipWrapper} color="#FFFF" title={tooltipData} >
              //   <img  className={style.reGenerateImg} onClick={() => {
              //   handleRegenrate();
              //   setGenerateText('Find below your regenerated narrative:');
              // }} src={constImages.RegenerateBtn} alt="" /> </Tooltip>
              <Button
                onClick={() => handleRegenrate()}
                className={style.reGenerate}
                loading={isLoadingRegenrate}
                disabled={isLoadingRegenrate}
              >
                Regenerate
              </Button>
            )}
          </div>
          <div className={style.btnDiv}>
            <div className={style.twoBtnDiv}>
              <GoBack
                isTooltip={true}
                onClickForFooterGo={() => {
                  handlegoback();
                  localStorage.setItem("isStopGeneration", String(true));
                }}
                isSubmitting={isSubmitting}
              />
              {!pathname.includes("/playground/") && (
                <SaveQuit onClickForFooter={handleSaveAndQuit} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={customStyle && customStyle}
          className={style.footerButtonsDiv}
        >
          <Form
            form={form}
            onFinish={withRegenerate ? handleRegenrate : handleSubmit}
          >
            {withRegenerate ? (
              <Button
                onClick={() => {
                  handleRegenrate();
                  setIsRegenerating(true);
                }}
                className={style.reGenerate}
                loading={isLoadingRegenrate}
                disabled={isLoadingRegenrate}
              >
                <RegenerateIcon /> Regenerate
              </Button>
            ) : (
              <div className={style.twoBtnDiv}>
                <Button
                  loading={isLoadingSubmit}
                  disabled={isLoadingSubmit || disableSubmit}
                  // onClick={() => handleSubmit()}
                  htmlType="submit"
                  className={style.nextButton}
                >
                  {isSubmit ? "Next" : "Submit"}
                </Button>
                {getUserType === "enterprise" &&
                  isHiddenModelSelection !== true && (
                    <Select
                      defaultValue="Pegasus"
                      style={{ width: "150px", textAlign: "start" }}
                      suffixIcon={<img src={down} alt={"down icon"} />}
                      onChange={(value) => changeModelType(value)}
                      value={getSelectedModelType}
                      optionLabelProp="value"
                      options={[
                        {
                          label: "Pegasus",
                          value: "Pegasus",
                        },
                        {
                          label: "Mentor",
                          value: "Mentor",
                        },
                        // {
                        //   label: (
                        //     <span className={style.label}>
                        //       In-house
                        //       <img src={testSvg} alt={"test icon"} width={50} />
                        //     </span>
                        //   ),
                        //   value: "In-house",
                        // },
                      ]}
                    />
                  )}
              </div>
            )}
            {/* <Button
              className={style.quickNextButton}
              onClick={() => navigate(handleQuickNext)}
            >
              Quick next
            </Button> */}
          </Form>
          <div className={style.btnDiv}>
            <div className={style.twoBtnDiv}>
              {/*{isSubmitting ? (*/}
              {/*  <div*/}
              {/*    className={style.stopButton}*/}
              {/*    onClick={() => {*/}
              {/*      setIsSubmitting(false);*/}
              {/*      localStorage.setItem("isStopGeneration", String(true));*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    Stop*/}
              {/*  </div>*/}
              {/*) : (*/}
              {/*  !isHiddenGoBackButton && (*/}
              {/*    <GoBack*/}
              {/*      isTooltip={isTooltip}*/}
              {/*      onClickForFooterGo={handlegoback}*/}
              {/*      isSubmitting={isSubmitting}*/}
              {/*    />*/}
              {/*  )*/}
              {/*)}*/}
              {!isHiddenGoBackButton && (
                <GoBack
                  isTooltip={true}
                  onClickForFooterGo={() => {
                    setIsSubmitting && setIsSubmitting(false);
                    localStorage.setItem("isStopGeneration", String(true));
                    handlegoback();
                  }}
                  // isSubmitting={isSubmitting}
                />
              )}
              {!pathname.includes("/playground/") && (
                <SaveQuit onClickForFooter={handleSaveAndQuit} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default observer(CommonFooterButton);
