import Spinner from "@assets/icons/spinner.svg";
import style from "./style.module.scss";

const SpinnerGeneration = () => {
  return (
    <div className={style.systemGeneratingResponse}>
      <img src={Spinner} /> Generating a response
    </div>
  );
};

export default SpinnerGeneration;
