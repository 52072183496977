import { observer } from "mobx-react";
import style from "./style.module.scss";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import Footer from "./footer";
import SignupTrialModal from "@components/layout/main-layout/public-layout/signup-trial-modal";
import React, { useState } from "react";
import { useStore } from "@stores/root-store";

const Home = observer(() => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(
    localStorage.getItem("showInfoModal"),
  );
  const [openPremiumModal, setOpenPremiumModal] = useState(false);

  const [output, setOutput] = useState("");

  const {
    user: { getUserType },
  } = useStore(null);

  const userType = getUserType;

  return (
    <div>
      <div className={style.homePagePageContainer}>
        <div className={style.homePageContainerCol}>
          <div className={style.homePageContainer}>
            <h1 style={{ textAlign: "start", lineHeight: "130%" }}>
              Welcome to Climate Finance Copilot
            </h1>
            <div className={style.paraContainer}>
              <p>
                Your expert advisor for all things climate finance. Start by
                selecting one of the options below.
              </p>
            </div>
            <Row className={style.boxesContiner} gutter={20}>
              <Col md={12} xs={24} sm={24}>
                <div
                  onClick={() => navigate(constRoute?.selectDonor)}
                  className={style.newProject}
                >
                  <h4> New Project </h4>
                </div>
              </Col>
              <Col md={12} xs={24} sm={24}>
                <div
                  onClick={() => navigate(constRoute?.existingProject)}
                  className={style.existingProject}
                >
                  <h4>Open Existing Project</h4>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <SignupTrialModal open={openModal} setOpenModal={setOpenModal} />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
});

export default Home;
