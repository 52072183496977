import welcomeLogo from "@assets/images/welcomeLogo.png";
import { useStore } from "@stores/root-store";
import { constRoute } from "@utils/route";
import { Button, Form, Input, Spin } from "antd";
import { observer } from "mobx-react";
import { memo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import style from "./style.module.scss";
import { frontendUrl } from "@api/const";
console.log('fe url is ', frontendUrl)
const ForgotPassword = observer(() => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    user: { onSendResendEmail, isLoadingResendEmail }
  } = useStore(null);

  const onFormSubmit = async values => {
    const payload = {
      email: values?.email.toLocaleLowerCase(),
      link: `${frontendUrl}/#/set-new-password`
    };
    const res = await onSendResendEmail(payload);
    localStorage.setItem("resendEmail", values?.email);
    if (res?.message?.length > 0) {
      navigate(constRoute?.checkEmail);
    }
  };

  const validateMessages = {
    required: "email is required!",
    types: {
      email: "email is not a valid email!"
    }
  };

  useEffect(() => {
    const body = document.getElementById("app-layout");
    body.classList.add(style.bgWhite);

    return () => {
      body.classList.remove(style.bgWhite);
    };
  }, []);

  return (
    <div className={style.mainContainer}>
      <div className={style.container}>
        <div className={style.headingWrapper}>
          <img src={welcomeLogo} alt="janus-logo" className={style.janusLogo} />
          <h2 className={style.forgotPassword}>Forgot Password?</h2>
          <p className={style.janusText}>
            Don’t sweat it, we will email you reset instructions to set a new
            password.
          </p>
        </div>
        <Form
          className={style.formData}
          form={form}
          onValuesChange={e => {}}
          autoComplete="false"
          onFinish={onFormSubmit}
          validateMessages={validateMessages}
          layout="vertical"
        >
          <Form.Item
            className={style.enterEmail}
            name={"email"}
            label="Email Address"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please Enter a valid Email"
              }
            ]}
          >
            <Input type="email" className={style.emailInput} />
          </Form.Item>
          <div className={style.loginWrraper}>
            <Button className={style.resendClickBtn} htmlType="submit">
              {(isLoadingResendEmail && <Spin />) || "Continue"}{" "}
            </Button>
          </div>
        </Form>
        <div className={style.outerLink}>
          <p>Back to</p>{" "}
          <Link to={constRoute?.login} className={style.aLink}>
            Login
          </Link>
        </div>
      </div>
    </div>
  );
});

export default memo(ForgotPassword);
